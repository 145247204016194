export default {
  MENUS: [
    {
      title: 'Tổ chức',
      name: 'Organizations',
      type: 'organizations',
      icon: 'fas fa-users-cog',
      show: true
    },
    {
      title: 'Dashboard',
      name: 'Home',
      type: 'home',
      icon: 'fas fa-tachometer-alt',
      show: false
    },
    {
      title: 'Đất nước',
      view: 'shared',
      name: 'Country',
      type: 'country',
      icon: 'fas fa-globe',
      index: {
        titles: [{ label: 'ĐẤT NƯỚC', value: 'country' }],
        columns: ['stt', 'avatar', 'name', 'created_at', 'language', 'actions'],
        actions: ['edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'vrtour', 'description', 'content', 'vehicle_info_desc', 'vehicle_info', 'local_cuisine_desc', 'local_cuisine', 'travel_experience_desc', 'travel_experience', 'festival_info_desc', 'festival_info', 'fun_facts_desc', 'fun_facts', 'status', 'category_id', 'location_address', 'location_longitude', 'location_latitude', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'vrtour', 'description', 'content', 'vehicle_info', 'local_cuisine', 'travel_experience', 'festival_info', 'fun_facts'],
        leftCards: ['groups'],
        rightCards: [
          'display',
          'category',
          'address',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'area',
        'dashboard',
        'kiosk',
        'lookup',
        'attraction',
        'museum',
        'event',
        'stay',
        'cuisine',
        'entertainment',
        'company',
        'health',
        'real-estate',
        'government-agency',
        'financial',
        'golf',
        'restaurant',
        'craft-village',
        'travel-experience',
        'other',
        'campaign',
        'tour'
      ]
    },
    {
      title: 'Khu vực',
      view: 'shared',
      name: 'Area',
      type: 'area',
      icon: 'fas fa-city',
      index: {
        titles: [{ label: 'KHU VỰC', value: 'area' }, { label: 'KHU VỰC CON', value: 'area children', children: true }],
        columns: ['stt', 'avatar', 'name', 'parent', 'country', 'created_at', 'language', 'actions'],
        actions: ['edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'vrtour', 'description', 'content', 'vehicle_info_desc', 'vehicle_info', 'local_cuisine_desc', 'local_cuisine', 'travel_experience_desc', 'travel_experience', 'festival_info_desc', 'festival_info', 'fun_facts_desc', 'fun_facts', 'status', 'category_id', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'vrtour', 'description', 'content', 'vehicle_info', 'local_cuisine', 'travel_experience', 'festival_info', 'fun_facts'],
        leftCards: ['groups'],
        rightCards: [
          'display',
          'category',
          'address',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'area',
        'dashboard',
        'kiosk',
        'lookup',
        'attraction',
        'museum',
        'event',
        'stay',
        'cuisine',
        'entertainment',
        'company',
        'health',
        'real-estate',
        'government-agency',
        'financial',
        'golf',
        'restaurant',
        'craft-village',
        'travel-experience',
        'other',
        'campaign',
        'post',
        'tour'
      ]
    },
    {
      title: 'Trang chủ',
      view: 'shared',
      name: 'Dashboard',
      type: 'dashboard',
      icon: 'fas fa-home',
      index: {
        titles: [{ label: 'TRANG CHỦ', value: 'dashboard' }],
        columns: ['stt', 'avatar', 'name', 'country', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'kiosk',
        'lookup',
        'museum',
        'lookup',
        'hierarchy'
      ]
    },
    {
      title: 'Không gian văn hoá',
      view: 'shared',
      name: 'Museum',
      type: 'museum',
      icon: 'fas fa-mosque',
      index: {
        titles: [{ label: 'KHÔNG GIAN VĂN HOÁ', value: 'museum' }, { label: 'KHÔNG GIAN VĂN HOÁ CON', value: 'museum children', children: true }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'related', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'structure',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'kiosk',
        'museum',
        'lookup',
        'tour',
        'post',
        'hierarchy'
      ],
      related: [
        'museum',
        'attraction',
        'event'
      ]
    },
    {
      title: 'Nhân vật',
      view: 'shared',
      name: 'People',
      type: 'people',
      icon: 'fas fa-user',
      index: {
        titles: [{ label: 'Nhân vật', value: 'people' }, { label: 'Nhân vật con', value: 'people children', children: true }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'related', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'status', 'date_of_birth', 'date_of_death', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'date_of_birth',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ],
      related: [
        'museum',
        'attraction',
        'event'
      ]
    },
    {
      title: 'Kiosk',
      view: 'shared',
      name: 'Kiosk',
      type: 'kiosk',
      icon: 'fas fa-tablet-alt',
      index: {
        titles: [{ label: 'KIOSK', value: 'kiosk' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'status', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'place_parent',
          'display',
          'structure',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'lookup',
        'hierarchy'
      ]
    },
    {
      title: 'Tra cứu',
      view: 'shared',
      name: 'Lookup',
      type: 'lookup',
      icon: 'fas fa-filter',
      index: {
        titles: [{ label: 'TRA CỨU', value: 'lookup' }, { label: 'TRA CỨU CON', value: 'lookup children', children: true }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'status', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'place_parent',
          'display',
          'structure',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'lookup',
        'hierarchy'
      ]
    },
    {
      title: 'Danh thắng',
      view: 'shared',
      name: 'Attraction',
      type: 'attraction',
      icon: 'fas fa-place-of-worship',
      index: {
        titles: [{ label: 'DANH THẮNG', value: 'attraction' }, { label: 'DANH THẮNG CON', value: 'attraction children', children: true }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'attraction',
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Giao thông',
      view: 'shared',
      name: 'Traffic',
      type: 'traffic',
      icon: 'fas fa-traffic-light',
      index: {
        titles: [{ label: 'GIAO THÔNG', value: 'traffic' }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'attraction',
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Sự kiện và triển lãm',
      view: 'shared',
      name: 'Event',
      type: 'event',
      icon: 'fas fa-calendar-week',
      index: {
        titles: [{ label: 'SỰ KIỆN VÀ TRIỂN LÃM', value: 'event' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'place_duration', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'times',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Lễ hội',
      view: 'shared',
      name: 'Festival',
      type: 'festival',
      icon: 'fas fa-american-sign-language-interpreting',
      index: {
        titles: [{ label: 'Lễ hội', value: 'festival' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'place_duration', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'times',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Gia đình',
      view: 'shared',
      name: 'Family',
      type: 'family',
      icon: 'fas fa-house-user',
      index: {
        titles: [{ label: 'GIA ĐÌNH', value: 'family' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'place_duration', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'times',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Địa điểm lưu trú',
      view: 'shared',
      name: 'Stay',
      type: 'stay',
      icon: 'fas fa-bed',
      index: {
        titles: [{ label: 'ĐỊA ĐIỂM LƯU TRÚ', value: 'stay' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['facility', 'services', 'artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Ăn uống',
      view: 'shared',
      name: 'Cuisine',
      type: 'cuisine',
      icon: 'fas fa-utensils',
      index: {
        titles: [{ label: 'ĂN UỐNG', value: 'cuisine' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['services', 'artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Giải trí',
      view: 'shared',
      name: 'Entertainment',
      type: 'entertainment',
      icon: 'fas fa-play-circle',
      index: {
        titles: [{ label: 'GIẢI TRÍ', value: 'entertainment' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Doanh nghiệp',
      view: 'shared',
      name: 'Company',
      type: 'company',
      icon: 'fas fa-building',
      index: {
        titles: [{ label: 'DOANH NGHIỆP', value: 'company' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Chăm sóc sức khỏe',
      view: 'shared',
      name: 'Health',
      type: 'health',
      icon: 'fas fa-heart',
      index: {
        titles: [{ label: 'CHĂM SÓC SỨC KHỎE', value: 'health' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Bất động sản',
      view: 'shared',
      name: 'Real-estate',
      type: 'real-estate',
      icon: 'fas fa-landmark',
      index: {
        titles: [{ label: 'BẤT ĐỘNG SẢN', value: 'real-estate' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Cơ quan nhà nước',
      view: 'shared',
      name: 'Government-agency',
      type: 'government-agency',
      icon: 'fas fa-building',
      index: {
        titles: [{ label: 'CƠ QUAN NHÀ NƯỚC', value: 'government-agency' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Tài chính',
      view: 'shared',
      name: 'Financial',
      type: 'financial',
      icon: 'fas fa-dollar-sign',
      index: {
        titles: [{ label: 'TÀI CHÍNH', value: 'financial' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Golf',
      view: 'shared',
      name: 'Golf',
      type: 'golf',
      icon: 'fas fa-golf-ball',
      index: {
        titles: [{ label: 'GOLF', value: 'golf' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Nhà hàng',
      view: 'shared',
      name: 'Restaurant',
      type: 'restaurant',
      icon: 'fas fa-concierge-bell',
      index: {
        titles: [{ label: 'NHÀ HÀNG', value: 'restaurant' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'cook', 'cashier', 'tag', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['facility', 'services', 'groups', 'foods', 'food_groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Làng nghề',
      view: 'shared',
      name: 'Craft-village',
      type: 'craft-village',
      icon: 'fas fa-certificate',
      index: {
        titles: [{ label: 'LÀNG NGHỀ', value: 'craft-village' }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['copy', 'showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Kinh nghiệm du lịch',
      view: 'shared',
      name: 'Travel-experience',
      type: 'travel-experience',
      icon: 'fas fa-suitcase-rolling',
      index: {
        titles: [{ label: 'KINH NGHIỆM DU LỊCH', value: 'travel-experience' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Quy Y',
      view: 'shared',
      name: 'Refuge',
      type: 'refuge',
      icon: 'fas fa-vihara',
      index: {
        titles: [{ label: 'QUY Y', value: 'refuge' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Hoạt động',
      view: 'shared',
      name: 'Activity',
      type: 'activity',
      icon: 'fas fa-skating',
      index: {
        titles: [{ label: 'HOẠT ĐỘNG', value: 'activity' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Phật học',
      view: 'shared',
      name: 'Buddhist-learning',
      type: 'buddhist-learning',
      icon: 'fas fa-dharmachakra',
      index: {
        titles: [{ label: 'PHẬT HỌC', value: 'buddhist-learning' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Kinh phật',
      view: 'shared',
      name: 'Buddhist-scripture',
      type: 'buddhist-scripture',
      icon: 'fas fa-om',
      index: {
        titles: [{ label: 'KINH PHẬT', value: 'buddhist-scripture' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Giáo hội',
      view: 'shared',
      name: 'Church',
      type: 'church',
      icon: 'fas fa-church',
      index: {
        titles: [{ label: 'GIÁO HỘI', value: 'church' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Thiện nguyện',
      view: 'shared',
      name: 'Volunteer',
      type: 'volunteer',
      icon: 'fas fa-hands-helping',
      index: {
        titles: [{ label: 'THIỆN NGUYỆN', value: 'volunteer' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Nghĩa trang',
      view: 'shared',
      name: 'Cemetery',
      type: 'cemetery',
      icon: 'fas fa-synagogue',
      index: {
        titles: [{ label: 'NGHĨA TRANG', value: 'cemetery' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Hỏi đáp',
      view: 'shared',
      name: 'Qna',
      type: 'qna',
      icon: 'fas fa-question',
      index: {
        titles: [{ label: 'HỎI ĐÁP', value: 'qna' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Bảo tàng video',
      view: 'shared',
      name: 'Video-library',
      type: 'video-library',
      icon: 'fas fa-video',
      index: {
        titles: [{ label: 'BẢO TÀNG VIDEO', value: 'video-library' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Bảo tàng âm thanh',
      view: 'shared',
      name: 'Sound-library',
      type: 'sound-library',
      icon: 'fas fa-volume-up',
      index: {
        titles: [{ label: 'BẢO TÀNG ÂM THANH', value: 'sound-library' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Bảo tàng tài liệu',
      view: 'shared',
      name: 'Document-library',
      type: 'document-library',
      icon: 'fas fa-book',
      index: {
        titles: [{ label: 'BẢO TÀNG TÀI LIỆU', value: 'document-library' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Bảo tàng hình ảnh',
      view: 'shared',
      name: 'Image-library',
      type: 'image-library',
      icon: 'fas fa-image',
      index: {
        titles: [{ label: 'BẢO TÀNG HÌNH ẢNH', value: 'image-library' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          // 'packages',//
          'place_parent',
          'display',
          'display_model',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Dịch vụ khác',
      view: 'shared',
      name: 'Other',
      type: 'other',
      icon: 'fas fa-align-justify',
      index: {
        titles: [{ label: 'DỊCH VỤ KHÁC', value: 'other' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'description', 'content', 'status', 'price_info', 'opening_time', 'opening_day', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups', 'background'],
        rightCards: [
          'display',
          'price',
          'days',
          'hours',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Chương trình KM',
      view: 'shared',
      name: 'Campaign',
      type: 'campaign',
      icon: 'fab fa-cuttlefish',
      index: {
        titles: [{ label: 'CHƯƠNG TRÌNH KHUYẾN MẠI', value: 'campaign' }],
        columns: ['stt', 'cam_avatar', 'cam_name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'status', 'price_info', 'location_address', 'location_longitude', 'location_latitude', 'country_id', 'area_id', 'contact_info_phone', 'contact_info_email', 'contact_info_website', 'facebook_url', 'zalo_url', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['cam_name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content'],
        leftCards: ['artifacts', 'artifact_groups', 'groups'],
        rightCards: [
          'display',
          'price',
          'address',
          'contact',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      }
    },
    {
      title: 'Bài viết Blog - chia sẻ',
      view: 'shared',
      name: 'Post',
      type: 'post',
      icon: 'fas fa-file',
      apiEndpoint: '/post',
      index: {
        titles: [{ label: 'BÀI VIẾT BLOG - CHIA SẺ', value: 'post' }],
        columns: ['stt', 'avatar', 'name', 'parent', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'detail', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map', 'status', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
        leftCards: ['artifacts', 'artifact_groups', 'groups'],
        rightCards: [
          'display',
          'display_model',
          'address',
          'place',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      },
      detail: [
        'tour',
        'post',
        'hierarchy'
      ]
    },
    {
      title: 'Tour du lịch mẫu',
      view: 'shared',
      name: 'Tour',
      type: 'tour',
      icon: 'fab fa-foursquare',
      apiEndpoint: '/tour',
      index: {
        titles: [{ label: 'TOUR DU LỊCH MẪU', value: 'tour' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'price', 'distance', 'description', 'content', 'agent_info', 'policy', 'tip_hour', 'status', 'country_id', 'area_id', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['t_name', 'ordered', 'qr_code', 'price', 'distance', 'description', 'content', 'agent_info', 'policy', 'tip_hour'],
        leftCards: ['schedule', 'groups'],
        rightCards: [
          // 'packages',//
          'display',
          'place',
          'address',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      }
    },
    {
      title: 'Tour du lịch thương mại',
      view: 'shared',
      name: 'Commerce',
      type: 'commerce',
      icon: 'fas fa-plane-departure',
      index: {
        titles: [{ label: 'TOUR DU LỊCH THƯƠNG MẠI', value: 'commerce' }],
        columns: ['stt', 'avatar', 'name', 'organization', 'created_at', 'language', 'actions'],
        actions: ['showQR', 'edit', 'delete'],
        headerActions: ['add', 'filter', 'reset', 'synchronize', 'import', 'export'],
        excelColumns: ['id', 'name', 'ordered', 'qr_code', 'price', 'distance', 'description', 'content', 'agent_info', 'policy', 'tip_hour', 'status', 'country_id', 'area_id', 'country_id', 'area_id', 'category_id', 'tags', 'youtube_links']
      },
      show: {
        inputs: ['t_name', 'ordered', 'qr_code', 'price', 'distance', 'description', 'content', 'agent_info', 'policy', 'tip_hour'],
        leftCards: ['schedule', 'groups'],
        rightCards: [
          // 'packages',//
          'display',
          'place',
          'address',
          'category',
          'tags',
          'youtube',
          'audios',
          'pictures',
          'videos'
        ]
      }
    },
    {
      title: 'Phân hệ',
      name: 'Hierarchy',
      type: 'hierarchy',
      icon: 'fas fa-sitemap',
      show: false,
      detail: true,
      apiEndpoint: '/hierarchy'
    },
    {
      title: 'Bình luận',
      view: 'shared',
      name: 'Comment',
      type: 'comment',
      icon: 'fas fa-comment',
      index: {
        titles: [{ label: 'BÌNH LUẬN ĐÃ DUYỆT', value: 'true' }, { label: 'BÌNH LUẬN CHƯA DUYỆT', value: 'false' }],
        columns: ['stt', 'c_object', 'c_creator', 'content', 'c_approved', 'c_created_at', 'actions'],
        actions: ['browser', 'unbrowser', 'delete'],
        headerActions: ['reset', 'export'],
        excelColumns: ['id', 'object', 'creator', 'content', 'approved']
      },
      apiEndpoint: '/comment'
    },
    {
      title: 'Trang Banner',
      name: 'Pages',
      type: 'pages',
      icon: 'fas fa-ad',
      show: false,
      apiEndpoint: '/banners'
    },
    {
      title: 'Danh mục',
      name: 'Category',
      type: 'category',
      icon: 'fas fa-folder',
      show: false,
      index: {
        excelColumns: ['id', 'name']
      }
    },
    {
      title: 'Thẻ Tag',
      name: 'Tags',
      type: 'tags',
      icon: 'fas fa-tag',
      show: false
    },
    {
      title: 'Package',
      name: 'Package',
      type: 'package',
      icon: 'fas fa-box',
      show: false,
      apiEndpoint: '/package'
    },
    {
      title: 'Phòng ban',
      name: 'Department',
      type: 'department',
      icon: 'fas fa-laptop-house',
      show: false,
      apiEndpoint: '/department'
    },
    {
      title: 'Nơi làm việc',
      name: 'WorkingLocation',
      type: 'working-location',
      icon: 'fas fa-place-of-worship',
      show: false,
      apiEndpoint: '/working_location'
    },
    {
      title: 'Quản trị viên',
      name: 'Staff',
      type: 'staff',
      icon: 'fas fa-id-badge',
      show: false
    },
    {
      title: 'Người dùng',
      name: 'User',
      type: 'user',
      icon: 'fas fa-users',
      show: false
    },
    {
      title: 'Hỗ trợ',
      name: 'Suppoet',
      type: 'support',
      icon: 'fas fa-headset',
      show: false
    }
  ]
}
