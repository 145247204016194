<template>
  <div v-loading.fullscreen.lock="callingAPI" class="manage-layout">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="manage-layout__header"
    >
      <el-row class="header-left" type="flex" align="middle">
        <i class="el-icon-dish mr-3" @click.stop="handleGoBack"></i>
        <h3>{{ currentPage.label }}</h3>
      </el-row>
      <el-row class="header-right" type="flex" align="middle" justify="end">
        <span class="user-name">
          {{ loginInfo().auth_user.name }}
        </span>
        <span @click="handleLogout">
          <i class="fas fa-sign-out-alt btn-logout" />
        </span>
      </el-row>
    </el-row>
    <div class="manage-layout__content">
      <div class="manage-layout__content--center">
        <router-view ref="manage-page" />
      </div>
    </div>
  </div>
</template>

<script>
import { loginInfo, logout } from '@/services/auth'
import DISH from '@/data/dish'

export default {
  name: 'ManageLayout',
  components: {},
  data() {
    return {
      callingAPI: false
    }
  },
  computed: {
    currentPage() {
      return DISH.MANAGE_PAGES.find((p) => p.name === this.$route.name)
    }
  },
  methods: {
    loginInfo,

    handleLogout() {
      this.$confirm('Bạn có chắc chắn muốn đăng xuất không?', 'Xác nhận', {
        confirmButtonText: 'Đăng xuất',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        logout()
      }).catch(() => {})
    },

    handleGoBack() {
      const user = loginInfo().auth_user
      if (['cook', 'cashier'].includes(user.staff_type)) {
        this.$refs['manage-page'].loadData()
      } else {
        this.$router.push({ name: 'Restaurant' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-layout {
  height: 100%;
  width: 100%;
  &__header {
    background-color: #2b314a;
    height: 50px;
    padding: 0 20px;
    color: white;
    .header-left {
      i {
        font-size: 32px;
        cursor: pointer;
      }
    }
    .header-right {
      height: 100%;
      .user-name {
        padding: 0 15px;
        border-right: 0.1px solid #fff;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .btn-logout {
        font-size: 24px;
        &:hover {
          cursor: pointer;
        }
        margin: 0 15px;
      }
    }
  }
  &__content {
    padding: 20px;
  }
}
</style>
